import React from 'react';

import './WhatWeProvide.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import WhatWeProvideCard from './components/WhatWeProvideCard/WhatWeProvideCard';

import Bike from '../../../../assets/images/Main/WhatWeProvide/Bike.png';
// import Hiking from '../../../../assets/images/Main/WhatWeProvide/Hiking.png';
import Yoga from '../../../../assets/images/Main/WhatWeProvide/Yoga.png';
import { Headline2 } from '../../../../components/Text/Headlines/Headlines';

const WhatWeProvide = () => {
  return (
    <div className="WhatWeProvide">
      <PageContainer>
        <Headline2>What we provide</Headline2>
        <div className="WhatWeProvide__cards">
          <WhatWeProvideCard image={Bike} subtitle={'Cycling tours'} />
          {/* <WhatWeProvideCard isSoon image={Hiking} subtitle={'Hiking'} /> */}
          {/* <WhatWeProvideCard isSoon image={Yoga} subtitle={'Yoga'} /> */}
        </div>
      </PageContainer>
    </div>
  );
};

export default WhatWeProvide;
