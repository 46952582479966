import React, { useState } from 'react';
import './SearchBar.scss';
import { Element } from 'react-scroll';
import { ButtonLText, ButtonMText } from '../Text/Body/Bodies';
import SignsIcon from '../../assets/icons/SignsIcon';
import BicycleIcon from '../../assets/icons/BicycleIcon';
import SunriseIcon from '../../assets/icons/SunriseIcon';
import { Add, Minus, Profile2User, SearchNormal1 } from 'iconsax-react';
import { Field, Form, Formik } from 'formik';
import InputDrop from '../InputDrop/InputDrop';
import ButtonL from '../Buttons/Buttons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  countriesOptionsState,
  personCountState,
  rideLevelOptionsState,
} from '../../recoil/Main/Main';
import DateField from '../../pages/TourForm/components/DateField/DateField';
import DateRangePickerField from '../DateRangePicker/DateRangePickerField';

const SearchBar = () => {
  const [activeTab, setActiveTab] = useState('Cycling');
  const [personCount, setPersonCount] = useRecoilState(personCountState);
  const countriesOptionsList = useRecoilValue(countriesOptionsState);
  const LevelOptionsList = useRecoilValue(rideLevelOptionsState);

  const handleSubmit = values => {
    console.log({ ...values, tour_type: activeTab });
  };

  return (
    <div className="SearchBar container">
      <Element className="SearchBar__controlls" name="Search Bar Controlls">
        <ul className="SearchBar__controlls-tabs">
          <li
            className="SearchBar__controlls-tabs__tab"
            value={'Cycling'}
            onClick={e => setActiveTab(e.target.getAttribute('value'))}
          >
            <div className="SearchBar__controlls-tabs__tab-text">
              <BicycleIcon />
              <ButtonMText variant={'dark900'}>Cycling</ButtonMText>
            </div>
            <div
              className={`SearchBar__controlls-tabs__tab-line ${activeTab === 'Cycling' ? 'is-active' : ''}`}
            ></div>
          </li>
          {/* <li
            className="SearchBar__controlls-tabs__tab"
            value={'Hiking'}
            onClick={e => setActiveTab(e.target.getAttribute('value'))}
          >
            <div className="SearchBar__controlls-tabs__tab-text">
              <SignsIcon />
              <ButtonMText variant={'dark900'}>Hiking</ButtonMText>
            </div>
            <div
              className={`SearchBar__controlls-tabs__tab-line ${activeTab === 'Hiking' ? 'is-active' : ''}`}
            ></div>
          </li> */}
          {/* <li
            className="SearchBar__controlls-tabs__tab"
            value={'Youga'}
            onClick={e => setActiveTab(e.target.getAttribute('value'))}
          >
            <div className="SearchBar__controlls-tabs__tab-text">
              <SunriseIcon />
              <ButtonMText variant={'dark900'}>Youga</ButtonMText>
            </div>
            <div
              className={`SearchBar__controlls-tabs__tab-line ${activeTab === 'Youga' ? 'is-active' : ''}`}
            ></div>
          </li> */}
        </ul>
        <div className="SearchBar__controlls-persons">
          <div className="SearchBar__controlls-persons__text">
            <Profile2User size="24" color="#747B8A" variant="Bold" />
            <ButtonMText variant={'dark900'}>Persons:</ButtonMText>
          </div>
          <div className="SearchBar__controlls-persons__counter">
            <div
              className="SearchBar__controlls-persons__counter-btn"
              onClick={() =>
                setPersonCount(personCount > 1 ? personCount - 1 : 1)
              }
            >
              <Minus size="20" />
            </div>
            <div className="SearchBar__controlls-persons__counter-count">
              <ButtonMText variant={'dark900'}>{personCount}</ButtonMText>
            </div>
            <div
              className="SearchBar__controlls-persons__counter-btn"
              onClick={() =>
                setPersonCount(personCount < 10 ? personCount + 1 : 10)
              }
            >
              <Add size="20" />
            </div>
          </div>
        </div>
      </Element>
      <Element className="SearchBar__content" name="Search Bar Content">
        <Formik
          initialValues={{
            destination: '',
            date: '',
            level: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form className="SearchBar__content-form">
            <Field
              name="destination"
              component={InputDrop}
              className="SearchBar__content-select"
              options={countriesOptionsList}
              placeholder="Destination"
            />
            <Field
              name="date"
              component={DateField}
              className="SearchBar__content-select"
              placeholder="Select date"
            />
            <Field
              name="level"
              component={InputDrop}
              className="SearchBar__content-select"
              options={LevelOptionsList}
              placeholder="Rider level"
            />
            <ButtonL action={'submit'}>
              <SearchNormal1 size="24" color="#FFFFFF" />
              <ButtonLText variant={'white'}>Search</ButtonLText>
            </ButtonL>
          </Form>
        </Formik>
      </Element>
    </div>
  );
};

export default SearchBar;
