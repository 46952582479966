import { backend } from './../index';

export const createApplication = async data => {
  try {
    const response = await backend.post('/application/', data);
    return response;
  } catch (error) {
    console.error('Error fetching about us:', error);
    throw error;
  }
};
