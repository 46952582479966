import './styles/index.scss';
import { useEffect } from 'react';
import routes from './routing/routes';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Login from './components/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import { userState } from './store/users/users.store';
import { ToastContainer } from 'react-toastify';
import PageLayout from './shared/PageLayout/PageLayout';
import { useLocation, useRoutes } from 'react-router-dom';
import Registration from './components/Registration/Registration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import { getAboutUs } from './api/AboutUs/AboutUs.api';
import {
  aboutUsState,
  countriesOptionsState,
  rideLevelOptionsState,
} from './recoil/Main/Main';
import { getCountries } from './api/Countries/Countries.api';
import { getLevels } from './api/Level/level.api';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Прокручивает страницу к началу
  }, [pathname]); // Этот эффект будет выполняться при каждом изменении пути

  return null;
};

function App() {
  const content = useRoutes(routes);
  const [User, setUser] = useRecoilState(userState);
  const [countriesOptionsList, setCountriesOptionsList] = useRecoilState(
    countriesOptionsState,
  );
  const [levelsOptionsList, setLevelsOptionsList] = useRecoilState(
    rideLevelOptionsState,
  );
  const [aboutUs, setAboutUs] = useRecoilState(aboutUsState);

  const fetchData = async () => {
    const response = await getAboutUs();
    setAboutUs(response.data);

    const countries = await getCountries();
    const countriesOptions = countries.data.results.map(country => {
      return { label: country.name, value: country.id };
    });
    setCountriesOptionsList(countriesOptions);

    const levels = await getLevels();
    const levelsOptions = levels.map(level => {
      return level.is_active && { label: level.title, value: level.id };
    });
    setLevelsOptionsList(levelsOptions);
  };

  useEffect(() => {
    fetchData();
    !User?.id && setUser(JSON.parse(localStorage.getItem('user')));
  }, [User, setUser]);

  useEffect(() => {
    console.log(aboutUs);
  }, [aboutUs]);

  return (
    <PageLayout>
      <ScrollToTop />
      <Login />
      <Registration />
      <ForgotPassword />
      <ToastContainer />
      {content}
    </PageLayout>
  );
}

export default App;
