import { atom } from 'recoil';

export const personCountState = atom({
  key: 'personCountState',
  default: 2,
});

export const aboutUsState = atom({
  key: 'aboutUsState',
  default: null,
});

export const countriesOptionsState = atom({
  key: 'countriesOptionsState',
  default: [],
});

export const rideLevelOptionsState = atom({
  key: 'rideLevelOptionsState',
  default: [],
});
