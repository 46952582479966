import React from 'react';
import * as Yup from 'yup';

import './AboutUs.scss';
import {
  Headline1,
  Headline2,
  Headline4,
} from '../../components/Text/Headlines/Headlines';

import HowItWorksCard from './components/HowItWorksCard/HowItWorksCard';
import AboutUsBanner from '../../assets/images/AboutUs/headerImage.jpeg';

import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import { ButtonL } from '../../components/Buttons/Buttons';
import WhatSayAboutUsCard from './components/WhatSayAboutUsCard/WhatSayAboutUsCard';
import { useEffect } from 'react';
import { useState } from 'react';
import { getAboutUs } from '../../api/AboutUs/AboutUs.api';
import { ButtonLText, ImportantText } from '../../components/Text/Body/Bodies';
import { Call, ExportSquare, Sms } from 'iconsax-react';
import { Field, Form, Formik } from 'formik';
import InputField from '../TourForm/components/InputField/InputField';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { getReviews } from '../../api/Reviews/Reviews.api';
import { createApplication } from '../../api/Application/Application.api';
import { useRecoilValue } from 'recoil';
import { aboutUsState } from '../../recoil/Main/Main';

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const aboutUs = useRecoilValue(aboutUsState);
  const [reviews, setReviews] = useState(null);

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }

  const validationSchema = Yup.object({
    firstname: Yup.string()
      .required('First name is required')
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name cannot exceed 50 characters'),

    lastname: Yup.string()
      .required('Last name is required')
      .min(2, 'Last name must be at least 2 characters')
      .max(50, 'Last name cannot exceed 50 characters'),

    email: Yup.string()
      .required('Email is required')
      .email('Invalid email address'),

    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^\+?[0-9]{7,15}$/, 'Invalid phone number format'),

    message: Yup.string()
      .required('Message is required')
      .min(10, 'Message must be at least 10 characters')
      .max(500, 'Message cannot exceed 500 characters'),
  });

  const fetchData = async () => {
    const responseReviews = await getReviews();
    setReviews(responseReviews?.data?.results);

    setLoading(false);
  };

  const handleCreateApplication = async values => {
    const response = await createApplication(values);

    console.log(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="AboutUs">
      <div
        className="AboutUs__header"
        style={{
          backgroundImage: aboutUs?.banner
            ? `url(${aboutUs?.banner})`
            : `url(${AboutUsBanner})`,
        }}
      >
        <Headline1 variant={'white'}>About us</Headline1>
      </div>

      <div className="container">
        <div className="AboutUs__wrapper">
          <Breadcrumbs
            items={[
              { path: '/', name: 'Home' },
              { path: '/about-us', name: 'About Us' },
            ]}
          />
          <div className="AboutUs__whoWeAre">
            <div className="AboutUs__whoWeAre__text">
              <Headline2>{aboutUs?.title || `Who we are`}</Headline2>

              <ImportantText variant={'dark720'}>
                {aboutUs?.description}
              </ImportantText>
            </div>

            <iframe
              width="486"
              height="216"
              src={aboutUs?.url}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className="AboutUs__howItWorks">
            <Headline2>How it works</Headline2>
            <div className="AboutUs__howItWorks__cards">
              {aboutUs?.gide_abouts?.map(gide => (
                <HowItWorksCard
                  icon={gide?.icon}
                  title={gide?.title}
                  description={gide?.description}
                />
              ))}
            </div>
          </div>

          <div className="AboutUs__gallery">
            <Headline2>Gallery</Headline2>
            <div className="AboutUs__gallery__images">
              <div className="AboutUs__gallery__leftSide">
                {aboutUs?.gallery?.slice(0, 3).map((image, index) => (
                  <img
                    src={image?.image}
                    alt=""
                    className={
                      index === 0
                        ? 'AboutUs__gallery__leftSide__big'
                        : 'AboutUs__gallery__leftSide__small'
                    }
                  />
                ))}
              </div>
              <div className="AboutUs__gallery__rightSide">
                {aboutUs?.gallery?.slice(3, 5).map((image, index) => (
                  <img
                    src={image?.image}
                    alt=""
                    className={'AboutUs__gallery__rightSide__image'}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="AboutUs__contact">
            <div className="AboutUs__contact__leftSide">
              <div className="AboutUs__contact__leftSide-text">
                <Headline2 variant={'dark900'}>Contact us</Headline2>
                <ImportantText variant={'dark720'}>
                  Any questions? We would be happy to help you!
                </ImportantText>
              </div>

              <div className="AboutUs__contact__leftSide-btns">
                <ButtonL
                  type={'outlined'}
                  fullWidth
                  iconLeftShow={<Call color="#007c6e" />}
                  onClick={() =>
                    (window.location.href = `tel:${aboutUs?.phone}`)
                  }
                >
                  <ButtonLText variant={'jungle500'}>
                    {aboutUs?.phone}
                  </ButtonLText>
                </ButtonL>
                <ButtonL
                  type={'tertiary'}
                  iconLeftShow={<Sms color="#007c6e" />}
                  onClick={() =>
                    (window.location.href = `mailto:${aboutUs?.phone}`)
                  }
                >
                  <ButtonLText variant={'jungle500'}>
                    {aboutUs?.mail}
                  </ButtonLText>
                </ButtonL>
              </div>

              <div className="AboutUs__contact__leftSide-socials">
                <Headline4 variant={'dark900'}>We are in social:</Headline4>
                <div className="AboutUs__contact__leftSide-socials__icons">
                  {aboutUs?.facebook && (
                    <a target="_blank" href={aboutUs?.facebook}>
                      <img src={FacebookIcon} />
                    </a>
                  )}
                  {aboutUs?.instagram && (
                    <a target="_blank" href={aboutUs?.instagram}>
                      <img src={InstagramIcon} />
                    </a>
                  )}
                  {aboutUs?.youtube && (
                    <a target="_blank" href={aboutUs?.youtube}>
                      <img src={YoutubeIcon} />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="AboutUs__contact__rightSide">
              <Formik
                initialValues={{
                  firstname: '',
                  lastname: '',
                  email: '',
                  phone: '',
                  message: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  // Отправляем данные формы
                  handleCreateApplication(values)
                    .then(() => {
                      // Успешная отправка — сбрасываем форму
                      resetForm();
                    })
                    .catch(error => {
                      // Обработка ошибок, если требуется
                      console.error('Error submitting form:', error);
                    })
                    .finally(() => {
                      // Завершаем процесс отправки (разблокировка кнопки)
                      setSubmitting(false);
                    });
                }}
              >
                {({ isValid, dirty, isSubmitting }) => (
                  <Form className="AboutUs__contact__rightSide-form">
                    <div className="AboutUs__contact__rightSide-form__fields">
                      <Field
                        name={'firstname'}
                        inputLabel="First Name"
                        placeholder={'First name'}
                        component={InputField}
                      />
                      <Field
                        inputLabel="Last Name"
                        name={'lastname'}
                        placeholder={'Last name'}
                        component={InputField}
                      />
                      <Field
                        inputLabel="Email"
                        name={'email'}
                        inputType="email"
                        placeholder={'Email'}
                        component={InputField}
                      />
                      <Field
                        inputLabel="Phone"
                        inputType="phone"
                        name={'phone'}
                        placeholder={'Phone number'}
                        component={InputField}
                      />
                      <Field
                        label={'Message'}
                        name={'message'}
                        placeholder={'Type your message here...'}
                        height={104}
                        component={InputField}
                      />
                    </div>
                    <ButtonL
                      action="submit"
                      iconRightShow={<ExportSquare color="#ffffff" />}
                      disabled={!isValid || !dirty || isSubmitting} // Отключаем кнопку при невалидной форме или процессе отправки
                    >
                      <ButtonLText variant={'white'}>Send message</ButtonLText>
                    </ButtonL>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {reviews?.length > 0 && (
            <div className="AboutUs__whaySayAboutUs">
              <Headline2>What say about us</Headline2>

              <div className="AboutUs__whaySayAboutUs__cards">
                {reviews.map((review, index) => (
                  <WhatSayAboutUsCard
                    customer={`${review?.author?.first_name} ${review?.author?.last_name}`}
                    comment={review?.content}
                    rating={review?.rating}
                    date={formatDate(review?.created_at)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
