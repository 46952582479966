// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhatWeProvide {
  background: #f5f6f7;
}
.WhatWeProvide__cards {
  margin-top: 50px;
  display: flex;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/WhatWeProvide/WhatWeProvide.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,gBAAA;EACA,aAAA;EAEA,qBAAA;AAAJ","sourcesContent":[".WhatWeProvide {\n  background: #f5f6f7;\n\n  &__cards {\n    margin-top: 50px;\n    display: flex;\n    // justify-content: space-around;\n    align-items: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
